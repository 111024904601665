import React, { useState } from "react";

export default function Nav() {
  const [collapse, isCollapse] = useState(true);

  const navHandler = (e) => {
    e.preventDefault();
    isCollapse(!collapse);
  };

  return (
    <>
      <nav className="flex justify-between p-4 z-40 transparent sticky">
        <ul className="flex">
          <li className="flex my-auto text-[#f2b211] text-xl font-bold">
            Brand
          </li>
        </ul>
        <ul className="flex gap-2 md:none md:hidden">
          <button
            className="w-10 h-10 rounded-md group"
            onClick={(e) => navHandler(e)}
          >
            <span
              className={`block  w-6 h-[3px] my-[5px] mx-auto transition group-hover:bg-[#f2b211] ease-in-out ${
                collapse ? "bg-white" : "rotate-45 mt-2 bg-black"
              }`}
            ></span>
            <span
              className={`w-6 h-[3px] my-[5px] mx-auto transition group-hover:bg-[#f2b211] ease-in-out ${
                collapse ? "block visible bg-white" : "none hidden bg-black"
              }`}
            ></span>
            <span
              className={`block w-6 h-[3px] my-[5px] mx-auto transition group-hover:bg-[#f2b211] ease-in-out ${
                collapse ? "bg-white" : "-rotate-45 -mt-2 bg-black"
              }`}
            ></span>
          </button>
        </ul>
        <ul className="none hidden gap-4 text-white text-md md:flex md:visible">
          <li className="inline my-auto">
            <a href="">Item</a>
          </li>
          <li className="inline my-auto">
            <a href="">Item</a>
          </li>
          <li className="inline my-auto">
            <a href="">Item</a>
          </li>
          <li className="inline my-auto">
            <a href="">Item</a>
          </li>
        </ul>
      </nav>

      <div
        className={`block fixed w-full h-full top-0 bg-white p-4 transition duration-300 ease-in-out z-50"
          ${collapse ? "translate-x-[9999px]" : "translate-x-[0]"}`}
      >
        <ul className="my-12">
          <li>
            <a href="">Item</a>
          </li>
          <li>
            <a href="">Item</a>
          </li>
          <li>
            <a href="">Item</a>
          </li>
          <li>
            <a href="">Item</a>
          </li>
        </ul>
      </div>
    </>
  );
}
